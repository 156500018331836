
import React from "react";
import DoubleArrow from "../assets/double_arrow.png"

const CompanyCard = ({ company, onClick }) => {
  const { url, timePeriod, description, logoSrc, logoAlt, position } = company;

  return (
      <div className="company-card" onClick={onClick}>
        <div className="company-card-header">
          <img src={logoSrc} alt={logoAlt} className="company-logo" />
          <img src={DoubleArrow} alt="open" className="bottom-panel-open-logo"/>
        </div>
        <p>
            <b className="company-card-position-name">{position}</b> <span className="company-card-position-period">({timePeriod})</span></p>
        <p className="company-card-description" style={{fontWeight:"200"}}>
            {description}</p>
      </div>
  );
};

export default CompanyCard;
