import React from "react"
import ReactMarkdown from "react-markdown"
import "../styles/landing.css"
import DoubleArrow from "../assets/double_arrow.png"
import gfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import "../styles/markdown-styles.css";

const CompanySheet = ({ isOpen, onClose, companyData }) => {
    // if (!isOpen) return null;
    if (companyData == null) return null;

    const animationClass = isOpen ? "opening" : "closing"

    const handleClose = () => {
        document.documentElement.classList.remove("no-scroll");
        const element = document.getElementById("company-sheet")
        if (element !== null) {
            element.classList.remove("opening")
            element.classList.add("closing")
        }
        const closeTimeout = setTimeout(() => {
            onClose()
          }, 500); // Adjust the timeout to match your CSS animation duration
        return () => clearTimeout(closeTimeout);
    };

    if (isOpen) document.documentElement.classList.add("no-scroll");

    return (
      <div className={`company-sheet-container`} onClick={handleClose}>
        <div id="company-sheet" className={`company-sheet ${animationClass}`} onClick={(e) => e.stopPropagation()}>
            <div className="company-sheet-header">
                <img src={DoubleArrow} className="close-button" onClick={handleClose}/>
                <img
                    className="company-logo company-logo-in-company-sheet"
                    src={companyData.logoSrc}
                    alt={companyData.logoAlt}
                />
            </div>  
            <div className="company-details">
                <div className="company-info">
                    <p className="company-position">{companyData.position}</p>
                    <p className="company-description-timeperiod">({companyData.timePeriod})</p>
                </div>
                <div className="tech-stack">
                    <div className="tech-items">
                        {companyData.techStack.map((tech) => (
                            <div key={tech} className="tech-item">
                                {tech}
                            </div>
                        ))}
                    </div> 
                </div>
                <ReactMarkdown className="markdown-content" remarkPlugins={[gfm]} rehypePlugins={[rehypeRaw]} children={companyData.long_description}></ReactMarkdown>
                <div className="company-website">
                    <p>Website:&nbsp;&nbsp;</p>
                    <a href={companyData.url} target="_blank" rel="noopener noreferrer">
                        {companyData.url}
                    </a>
                    
                </div>
            </div>
        </div>
      </div>
    );
  };
  
export default CompanySheet;
