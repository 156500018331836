import React, { useEffect, useState } from 'react';
import '../styles/landing.css'; // You can create a separate CSS file for styling
import CompanyCard from './company_card';
import CompanySheet from './company_sheet';
import MediumBlogsList from './medium_blog';
import Footer from './footer';
import CompanyCardsData from './company_data';
import Profile from './profile';


const LandingPage = () => {
    const [isHeaderTransparent, setHeaderTransparent] = useState(true);

    useEffect(() => {
        // Add an event listener to the 'scroll' event of the window
        window.addEventListener("scroll", handleScroll);

        // Cleanup: Remove the event listener when the component unmounts
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
    }, []);
  
    const handleScroll = () => {
      // Get the scroll position from the top of the window
      const scrollPosition = window.scrollY;
      const threshold = 150;
      let opacity = Math.min(1, scrollPosition / threshold);

      // Determine whether the header should be transparent or not
      if (scrollPosition > 0) {
        setHeaderTransparent(false);
      } else {
        setHeaderTransparent(true);
      }
      setHeaderTransparent(false);

      if (opacity > 0.7) {
        opacity = 0.7
      }

      document.documentElement.style.setProperty('--header-opacity', opacity);
    };

    // State to manage the company sheet
    const [selectedCompany, setSelectedCompany] = useState(null);

    const handleCompanyCardClick = (company) => {
        setSelectedCompany(company);
    };

    const handleCompanySheetClose = () => {
        setSelectedCompany(null);
    };

 return (
    <div className={`${selectedCompany !== null ? "no-scroll" : ""}`}>
    <div className={`background-image`}>
       <CompanySheet
                isOpen={selectedCompany !== null}
                onClose={handleCompanySheetClose}
                companyData={selectedCompany}
            />
      <header className={`header ${isHeaderTransparent ? 'transparent' : ''}`}>
            <div className="header-left"><b>Aniket Divekar</b></div>
            <div className="header-empty"></div>
            <a href='https://calendly.com/aniketdivekar/30min' target='_blank' rel="noopener nofollow"><button className="header-button">Talk to Me</button></a>
        </header>
        <div className="container">
            <div className="left-div">
                <Profile/>
            </div>
            <div className="right-div">
                <p className='experience_p'><b>Work Experience</b></p>
                <div className="company-cards-container">
                    <div className="company-cards">
                    {CompanyCardsData.map((company) => (
                        <CompanyCard
                            key={company.companyName}
                            company={company}
                            onClick={() => handleCompanyCardClick(company)}
                        />
                        ))}
                    </div>
                </div>
            </div>
        </div>
        <hr className="separator" />
        <section className="second-fold">
            <MediumBlogsList />
        </section>
        <hr className="separator" />
        <Footer /> {/* Add the Footer component */}
    </div>
    </div>

  );
};

export default LandingPage;