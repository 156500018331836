import React from 'react';

const BlogItem = ({ title, date, link, imageSrc, imageAlt }) => {
  return (
    <div className="blog-item">
      <a href={link} target="_blank" rel="noopener noreferrer">
        <div className="blog-image-container">
          <img src={imageSrc} alt={imageAlt} className="blog-image" />
          <div className="overlay">
            <b>{title}</b>
            <p>{date}</p>
          </div>
        </div>
      </a>
    </div>
  );
};

export default BlogItem;
