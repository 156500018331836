import WazirxLogo from '../assets/wazirx_logo.svg'
import CrowdfireLogo from '../assets/crowdfire_logo.svg'
import LusioLogo from '../assets/lusio_logo.svg'
import JPMCLogo from '../assets/jpmc_logo.svg'
import CitiusTechLogo from '../assets/citiustech_logo.svg'

const CompanyCardsData = [
    // Sample company card data objects
    {
        companyName: "Lusio",
        url: "https://lusio.gg",
        timePeriod: "Oct 2022 - Present",
        description: "At Lusio, I'm handling the tech and infra of the project.",
        logoSrc: LusioLogo,
        logoAlt: "Lusio",
        position: "Co-founder",
        techStack: [
            "Golang",
            "PostgreSQL",
            "Docker",
            "MS Azure",
            "SendGrid"
        ],
        long_description: `**_Lusio_** is a one-stop platform for gamers to discover web3 games and track & trade in-game assets.\r\n\r\nBuilt and set up the systems from scratch that can easily integrate thousands of games, act as a launchpad for games. We have 140+ games listed on Lusio with verified information and game wikis. We have integrations with multiple platforms internally to track NFT assets from these games.\r\n\r\nBuilt transaction analytics system that processed more than 100M transactions within few days to crunch analytical data to backfill data points and is up-to speed to process new transactions from multiple blockchains.`
    },
    {
        companyName: "WazirX",
        url: "https://wazirx.com",
        timePeriod: "May 2018 - Aug 2022",
        description: "At WazirX, I was responsible for building backend and have implemented most of the features on platform. I took over as EM when team expanded.",
        logoSrc: WazirxLogo,
        logoAlt: "WazirX",
        position: "Engineering Manager",
        techStack: [
            "Ruby on Rails",
            "Golang",
            "MySQL",
            "Docker",
            "Kubernetes",
            "Redis",
            "RabbitMQ",
            "MongoDB",
            "AWS"
        ],
        long_description: `**WazirX** is a biggest cryptocurrency exchange in India with more than 20M users.\r\n\r\nAs a founding engineer at WazirX, I played pivotal role in implementation of all the features on the backend, be it Exchange, P2P, Crypto & FIAT Deposit/Withdraw and many other features & internal services. These systems successfully accommodated more than 100K DAU, more than 500K daily orders with peak throughput of 100K/min, more than 14M daily socket events.\r\n\r\nTo summarize, I:\r\n\r\n- Led a team of 6 developers, successfully delivering multiple critical features. \r\n- Achieved a 60% improvement in trading engine's performance.\r\n- Onboarded and guided more than 25 members in the backend team on various projects.\r\n- Successfully integrated more than 50 crypto coins on multiple blockchains.\r\n- Architected and implemented a secure and robust crypto deposit and withdrawal service.\r\n- As an Engineering Manager, took responsibility for assessing the technical feasibility of backend features, conducting architecture and PR reviews.`
    },
    {
        companyName: "Crowdfire",
        url: "https://crowdfireapp.com",
        timePeriod: "July 2015 - Apr 2018",
        description: "At Crowdfire, I worked on growth features for users. I was also part of the team that built system for automated task suggestions for growing audience on social media.",
        logoSrc: CrowdfireLogo,
        logoAlt: "Crowdfire",
        position: "Technical Architect",
        techStack: [
            "NodeJS",
            "Golang",
            "Java",
            "MongoDB",
            "Aerospike",
            "Docker",
            "Kubernetes",
            "Redis",
            "AWS"
        ],
        long_description: `**_Crowdfire_** is a social media management tool that simplifies and improves the efficiency of social media managers.\r\n\r\nAt Crowdfire, I started as a Backend Engineer and then took over the position of Technical Architect, managing a team of 2 members.\r\n\r\nTo summarize, I\r\n\r\n- Implemented multiple growth features for the Twitter platform.\r\n- Designed, architected, and implemented the daily prescriptions service (tasks that help SMMs manage multiple social accounts), catering to more than 300K users, and generating more than 1M prescriptions daily.\r\n- Designed and implemented a scoring system to provide better suggestions for the prescriptions as per user behavior for previous tasks.`
    },
    {
        companyName: "J.P.Morgan Chase & Co.",
        url: "https://jpmorgan.com",
        timePeriod: "Dec 2014 - July 2015",
        description: "At J.P.Morgan Chase & Co., I worked Client Messaging team which was responsible for onboarding new client to Custody and Fund Services.",
        logoSrc: JPMCLogo,
        logoAlt: "JPMC",
        position: "Associate",
        techStack: [
            "Java",
        ],
        long_description: `**_J.P. Morgan Chase & Co._** is an Investment Banking firm providing solutions across multiple verticals.\r\n\r\nI joined J.P. Morgan Chase & Co. as an Associate in the Client Messaging Team.\r\n\r\nOur team had the responsibility of handling client-related messaging demands, including onboarding new clients and managing their corresponding interfacing.\r\n\r\nDuring my tenure, I was responsible for developing a messaging application that adhered to the architecture for Custody and Fund Services.`
    },
    {
        companyName:"CitiusTech",
        url: "https://citiustech.com/",
        timePeriod: "June 2012 - Dec 2014",
        description: "At CitiusTech, I started as a fresher where I worked with team at GE Healthcare for implementing Quality Reporting application for health records.",
        logoSrc: CitiusTechLogo,
        logoAlt: "CitiusTech",
        position: "Software Engineer",
        techStack: [
            "Java",
            "MS SQL"
        ],
        long_description:`**_CitiusTech_** is a Healthcare solutions provider.\r\n\r\nI joined CitiusTech as a fresher.\r\n\r\nWhile at CitiusTech, I worked with a team at GE Healthcare on a Quality Reporting Application. My responsibility was to develop an application that reads health documents, parses them to extract meaningful information, and stores it for generating Quality Reports required by the US Government for health reporting.`
    },
    // Add more company card data objects as needed
];

export default CompanyCardsData;
