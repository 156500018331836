import React from 'react';
import BlogItem from './blog_item';

const MediumBlogsList = () => {
  const blogs = [
    {
      title: 'What 10 Yrs Of Experience As SDE Taught Me',
      date: 'Sep 4, 2022',
      link: 'https://aniketdivekar.medium.com/what-10-yrs-of-experience-as-sde-has-taught-me-4c014eca3aca',
      imageSrc: 'https://miro.medium.com/v2/resize:fit:1200/format:webp/1*MIe_nw_yUhtU78hqhlsANA.jpeg',
      imageAlt: '',
    },
    {
      title: 'Bitcoin: Create, Sign And Push Transaction',
      date: 'Nov 13, 2019',
      link: 'https://medium.com/@aniketdivekar/bitcoin-create-sign-and-push-transaction-2ea877c08ab4',
      imageSrc: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*dZoqW37TBut6pap8aw7fLQ.jpeg',
      imageAlt: '',
    },
    {
      title: 'Jumping between the languages',
      date: 'Mar 26, 2017',
      link: 'https://medium.com/@aniketdivekar/jumping-between-the-languages-db3f0739ac1e',
      imageSrc: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*NTJDyP6B8vX1Z1qYhKyivQ.jpeg',
      imageAlt: '',
    }
  ];

  return (
    <div className="medium-blogs">
      <h2>Writing ✍🏻</h2>
      <div className="blogs-wrapper">
        <div className="blogs-container">
            {blogs.map((blog, index) => (
            <BlogItem
                key={index}
                title={blog.title}
                date={blog.date}
                link={blog.link}
                imageSrc={blog.imageSrc}
                imageAlt={blog.imageAlt}
            />
            ))}
        </div>
      </div>

    </div>
  );
};

export default MediumBlogsList;
