import React from "react";
import MapIcon from '../assets/map_icon.svg'
import me from '../assets/me.png'
import '../styles/landing.css';
import '../styles/profile.css';

const Profile = () => {
    const expYears = parseInt((new Date().getTime() - new Date('2012-06-06'))/31556952000)

    return (
        <>
            <div className="profile-image-container">
                <img
                    src={me}
                    alt="me"
                    className="center-image"
                />
                <div className="location">
                    <img src={MapIcon} alt='loc' />
                    <p>Mumbai, Maharashtra (IND)</p>
                </div>
            </div>
            <div className="profile-details">
                <div className="bio">
                    <p>
                        I am a passionate coder with more than {expYears} years of experience in Software Development.
                        <br/>I am currently building <a href="https://lusio.gg" target="_blank" rel="noopener nofollow"><b>Lusio</b></a> - Your web3 gaming sidekick.
                        <br/>Previously <a href="https://wazirx.com" target="_blank" rel="noopener nofollow"><b>WazirX</b></a> & <a href="https://crowdfireapp.com" target="blank" rel="noopener nofollow"><b>Crowdfire</b></a>.
                        <br/>Interested in Cricket, F1 & Web3.
                    </p>
                </div>
                <div className="social-details">
                    <a  href="https://github.com/aniketdivekar" 
                        target="_blank" 
                        rel="noopener nofollow"
                        ><i class="icon-github clickable-image home__details__social__icon"></i></a>
                    <a  href="https://twitter.com/aniketdivekar" 
                        target="_blank" 
                        rel="noopener nofollow"
                        ><i class="icon-x clickable-image home__details__social__icon"></i></a>
                    <a  href="https://medium.com/@aniketdivekar" 
                        target="_blank" 
                        rel="noopener nofollow"
                        ><i class="icon-medium clickable-image home__details__social__icon"></i></a>
                    <a  href="https://linkedin.com/in/aniketdivekar" 
                        target="_blank" 
                        rel="noopener nofollow"
                        ><i class="icon-linkedin clickable-image home__details__social__icon"></i></a>
                    <a  href="https://instagram.com/aniketsdivekar" 
                        target="_blank" 
                        rel="noopener nofollow"
                        ><i class="icon-instagram clickable-image home__details__social__icon"></i></a>
                </div>
            </div>
        </>
    );
}

export default Profile;